import React from 'react'

const Footer = () => {
    return (
        <nav className="footer">
            <p>Created By James Thomas</p>
        </nav>

    )
}

export default Footer